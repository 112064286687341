* {
  margin: 0;
  padding: 0;
}
body {
  margin: 0 !important;
}
.container {
  width: 95%;
  margin: auto;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.none-blink {
  background-color: #fff;
}

.blink{
  color: #fff;
  padding: 10px;
  display: inline-block;
  border-radius: 5px;
  animation: blinkingBackground 2s infinite;
}
@keyframes blinkingBackground{
  0%		{ background-color: yellow;}
  25%		{ background-color: white;}
  50%		{ background-color: yellow;}
  75%		{ background-color: white;}
  100%	        { background-color: yellow;}
}

.MuiBox-root.css-r91awh{
  width:103%;
}
.alert-shown {
  opacity: 1;
  transition: all 250ms linear;
}

.alert-hidden {
  opacity: 0;
  transition: all 250ms linear 2s;
}
.css-qp4e7h{
  font-size: 80px !important;
}
.css-rtm26p{
  max-width: 100px !important;
  max-height: 100px !important;
}
.css-udww7d,.css-8hppig{
  font-size: 20px !important;
}
.css-1w5ifpo{
  font-size: 36px !important;
}
.css-1kuy7z7{
  font-size: 20px !important;
}