@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* font-family: 'Lato', sans-serif; */
@font-face {
  font-family: MissMagnolia;
  src: url(assets/fonts/MissMagnolia-Script.woff);
}
@font-face {
  font-family: Oswald;
  src: url(assets/fonts/Oswald-Regular.woff);
}
.react-auto-scroll__option {
  display: none;
}
.vertical_text {
  writing-mode: vertical-rl;
  text-orientation:sideways;
}
.swiper-button-next{
  display:none;   
}
.swiper-button-prev{
  display:none;   
}
.swiper-pagination-bullets {
  display: none;
}